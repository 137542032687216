import {
  axiosInstance,
  axiosInstanceUnAuthenticated,
  API_BASE_URL,
} from "./axiosConfig";
import { setCookie, deleteCookie, getCookie } from "./utils/cookies";
import { ApiResponse } from "./models/models";
import { LoginResponse, UserProfile } from "./models/auth";
import { handleApiError } from "./errorHandlers/errorHandlers";

export const login = async (credentials) => {
  try {
    const url = `${API_BASE_URL}/usermgmt/knox/login/`;
    const response = await axiosInstanceUnAuthenticated.post(url, credentials);
    const loginResponse = LoginResponse.fromJson(response.data);
    setCookie("token", loginResponse.token, loginResponse.expiry);
    setCookie("userProfile", JSON.stringify(loginResponse.user));
    return ApiResponse.success(loginResponse);
  } catch (error) {
    deleteCookie("token");
    deleteCookie("userProfile");
    return handleApiError(error);
  }
};

export const register = async (userData) => {
  try {
    const url = `${API_BASE_URL}/usermgmt/signup/`;
    const response = await axiosInstanceUnAuthenticated.post(url, userData);
    return ApiResponse.success(response.data);
  } catch (error) {
    return handleApiError(error);
  }
};

export const getProfile = async (cacheFirst = true) => {
  if (cacheFirst) {
    try {
      const profileData = JSON.parse(getCookie("userProfile"));
      return ApiResponse.success(UserProfile.fromJson(profileData));
    } catch (e) {
      console.log("Error getting profile data from cookie!");
    }
  }

  try {
    const response = await axiosInstance.get("/usermgmt/user_profile/");
    const profileData = UserProfile.fromJson(response.data);
    setCookie("userProfile", JSON.stringify(profileData));
    return ApiResponse.success(profileData);
  } catch (error) {
    return handleApiError(error);
  }
};

export const logOut = async () => {
  try {
    const response = await axiosInstance.post("/usermgmt/knox/logout/");
    deleteCookie("token");
    deleteCookie("userProfile");
    return ApiResponse.success(response.data);
  } catch (error) {
    console.log(`Logout failed: ${JSON.stringify(error)}`);
    return handleApiError(error);
  } finally {
    deleteCookie("token");
    deleteCookie("userProfile");
  }
};

export const resendAccountVerificationEmail = async () => {
  try {
    const response = await axiosInstance.post(
      "/usermgmt/resend_email_verification/",
    );
    return ApiResponse.success(response.data);
  } catch (error) {
    return handleApiError(error);
  }
};

export const verifyEmail = async (token) => {
  try {
    if (!token) {
      throw new Error("Token is required");
    }
    const response = await axiosInstance.post("/usermgmt/verify_email/", {
      token: token,
    });
    return ApiResponse.success(response.data);
  } catch (error) {
    return handleApiError(error);
  }
};
