import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import profilePlaceholder from "../../assets/images/dummy2.jpg";
import editIcon from "../../assets/images/edit.svg";
import { getProfile } from "../../api/authApi";

const Profile = () => {
  const [profileImage, setProfileImage] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [profileData, setProfileData] = useState({
    first_name: "",
    last_name: "",
    email: "",
  });

  const handleImageUpload = (event) => {
    if (event.target.files && event.target.files[0]) {
      setProfileImage(URL.createObjectURL(event.target.files[0]));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Form submitted with phone number:", phoneNumber);
  };

  const fetchProfile = async () => {
    try {
      const userProfile = await getProfile();
      if (userProfile.success) {
        setProfileData(userProfile.data);
      }
    } catch (error) {
      console.error("Failed to fetch profile: ", error);
    }
  };

  useEffect(() => {
    fetchProfile();
  }, []);

  return (
    <div className="container mx-auto px-4 py-8">
      <nav className="mb-8">
        <ol className="flex items-center space-x-2 text-sm text-gray-500">
          <li>
            <NavLink to="/dashboard/home" className="hover:text-purple-600">
              Dashboard
            </NavLink>
          </li>
          <li>
            <span className="mx-2">/</span>
          </li>
          <li className="text-purple-600 font-medium">Profile</li>
        </ol>
      </nav>

      <div className="bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="p-8">
          <h2 className="text-2xl font-bold text-gray-800 mb-6">My Profile</h2>
          <div className="flex flex-col md:flex-row md:space-x-8">
            <div className="md:w-1/3 flex flex-col items-center mb-8 md:mb-0">
              <img
                src={profileImage || profilePlaceholder}
                alt="Profile"
                className="w-48 h-48 rounded-full object-cover mb-4 border-4 border-purple-200"
              />
              <div className="flex space-x-4">
                <label
                  htmlFor="profile-image-upload"
                  className="btn bg-purple-600 hover:bg-purple-700 text-white px-4 py-2 rounded-lg transition duration-300 ease-in-out flex items-center"
                >
                  <img src={editIcon} alt="Edit" className="w-4 h-4 mr-2" />
                  Update Photo
                </label>
                <input
                  id="profile-image-upload"
                  type="file"
                  accept="image/*"
                  onChange={handleImageUpload}
                  className="hidden"
                />
                <button className="btn bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-lg transition duration-300 ease-in-out">
                  Remove
                </button>
              </div>
            </div>
            <div className="md:w-2/3">
              <form onSubmit={handleSubmit} className="space-y-6">
                <div>
                  <label
                    htmlFor="first_name"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Full Name
                  </label>
                  <input
                    type="text"
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600 bg-gray-100"
                    id="first_name"
                    value={`${profileData.first_name} ${profileData.last_name}`}
                    readOnly
                    disabled
                  />
                </div>
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600 bg-gray-100"
                    id="email"
                    value={profileData.email}
                    readOnly
                    disabled
                  />
                </div>
                <div>
                  <label
                    htmlFor="phone_number"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600"
                    id="phone_number"
                    placeholder="Enter phone number"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </div>
                <div>
                  <button
                    type="submit"
                    className="bg-purple-600 hover:bg-purple-700 text-white px-6 py-2 rounded-lg transition duration-300 ease-in-out"
                  >
                    Save Changes
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
