export const taskStatus = {
  QUEUED: "QUEUED",
  IN_PROCESS: "IN_PROCESS",
  STARTING: "STARTING",
  FAILED: "FAILED",
  SUCCESS: "SUCCESS",
  EMPTY_OUTPUT: "EMPTY_OUTPUT",
};

export const AIFeatureCodes = {
  FACE_SWAP: "FACE_SWAP",
  BG_SWAP: "BG_SWAP",
  APPAREL_SWAP: "APPAREL_SWAP",
  // MODEL_SWAP: "MODEL_SWAP",
  // POSTURE_CHANGE: "POSTURE_CHANGE",
  // MQUINM: "MQUINM",
  // CMQUIN: "CMQUIN",
  DESC_GEN: "DESC_GEN",
  // MODEL_GEN: "MODEL_GEN",
  // BG_GEN: "BG_GEN",
  // UPSCALER: "UPSCALER",
  // CATALOGUER: "CATALOGUER",
  // INPAINT: "INPAINT",
  // OUTPAINT: "OUTPAINT",
};

export const AIFeatureViewNames = {
  FACE_SWAP: "Face Swap",
  BG_SWAP: "Backdrop Swap",
  APPAREL_SWAP: "Apparel Swap",
  // MODEL_SWAP: "Model Swap",
  // POSTURE_CHANGE: "Posture Change",
  // MQUINM: "Mannequin to Model",
  // CMQUIN: "Cloth to Mannequin",
  DESC_GEN: "Description Generator",
  // MODEL_GEN: "Model Generator",
  // BG_GEN: "Background Generator",
  // UPSCALER: "Upscaler",
  // CATALOGUER: "Cataloguer",
  // INPAINT: "Image Inpainting",
  // OUTPAINT: "Image Outpainting",
};

const Colors = {
  // color codes
};

export const ImageTypeCodes = {
  FACE: "FACE",
  MODEL_FULL: "MODEL_FULL",
  MODEL_UPPER_HALF: "MODEL_UPPER_HALF",
  MODEL_LOWER_HALF: "MODEL_LOWER_HALF",
  BACKDROP: "BACKDROP",
};

export const ImageTypeViewNames = {
  FACE: "Face",
  MODEL_FULL: "Model Full",
  MODEL_UPPER_HALF: "Model Upper Half",
  MODEL_LOWER_HALF: "Model Lower Half",
  BACKDROP: "Backdrop",
};
