import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "../Utils/ThemeContext";
import {
  AIFeatureViewNames,
  AIFeatureCodes,
  ImageTypeViewNames,
} from "../../config/constants";
import { getStaticImages } from "../../api/statics";
import { Loader, Button } from "../UIComponents/UIComponents";
import ChevronUp from "../../assets/images/chevron-up.svg";
import ChevronDown from "../../assets/images/chevron-down.svg";
import CheckIcon from "../../assets/images/Check.svg";

const Gallary = () => {
  const navigate = useNavigate();
  const { mode } = useContext(ThemeContext);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedGeneratedBy, setSelectedGeneratedBy] = useState([]);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedCatalogue, setSelectedCatalogue] = useState(false);
  const [error, setError] = useState(null);
  const [fetched, setFetched] = useState(false);
  const [visibleCount, setVisibleCount] = useState(8);
  const [categoriesDropdownOpen, setCategoriesDropdownOpen] = useState(true);
  const [generatedByDropdownOpen, setGeneratedByDropdownOpen] = useState(true);

  const [loadedImages, setLoadedImages] = useState({});
  const [imageErrors, setImageErrors] = useState({});

  const handleImageLoad = (id) => {
    setLoadedImages((prevState) => ({ ...prevState, [id]: true }));
    setImageErrors((prevState) => ({ ...prevState, [id]: false }));
  };

  const handleImageError = (id) => {
    setImageErrors((prevState) => ({ ...prevState, [id]: true }));
    setLoadedImages((prevState) => ({ ...prevState, [id]: false }));
  };

  const handleCategoryChange = (category) => {
    setSelectedCategory((prev) => {
      const updatedCategory = prev.includes(category)
        ? prev.filter((cat) => cat !== category)
        : [...prev, category];
      return updatedCategory;
    });
    setSelectedGeneratedBy([]);
    setGeneratedByDropdownOpen(false);
    setFetched(false);
    setVisibleCount(8);
  };

  const handleGeneratedByChange = (genType) => {
    setSelectedGeneratedBy((prev) => {
      const updatedGeneratedBy = prev.includes(genType)
        ? prev.filter((gen) => gen !== genType)
        : [...prev, genType];
      return updatedGeneratedBy;
    });
    setSelectedCategory([]);
    setCategoriesDropdownOpen(false);
    setFetched(false);
    setVisibleCount(8);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        let params = {};
        if (selectedGeneratedBy.length) {
          params = { source: selectedGeneratedBy.join(",") };
        } else if (selectedCategory.length) {
          params = { file_type: selectedCategory.join(",") };
        }
        const response = await getStaticImages(params.file_type, params.source);
        setImages(response.data || []);
        setFetched(true);
      } catch (error) {
        setError(error.response ? error.response.data.message : error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedCategory, selectedGeneratedBy, fetched]);

  const handleCatalogue = async (value) => {
    try {
      setSelectedCatalogue((prevCatalogue) => {
        return !prevCatalogue;
      });

      if (!selectedCatalogue) {
        const response = await getCatalogueImages(value);
        // setImages(response.data || []);
        console.log("Catalogue images:", response);
      }
    } catch (error) {
      console.error("Error fetching catalogue images:", error.message);
      // setError(error.response ? error.response.data.message : error.message);
    }
  };
  const handleUploadRedirect = () => {
    navigate("/dashboard/galary/UploadImage");
  };

  const showMoreImages = () => {
    setVisibleCount((prevCount) => prevCount + 8);
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const closePreview = () => {
    setSelectedImage(null);
  };

  return (
    <div
      className={`background-gradient flex flex-col min-h-screen ${
        mode === "light-mode" ? "text-gray-900" : "text-gray-500"
      } pt-20 transition-colors duration-500`}
    >
      <div className="justify-between items-center mb-4 pt-8">
        <div className="text-center">
          <h1 className="text-[24px] sm:text-[28px] md:text-[42px] font-sans font-bold">
            Image Gallery
          </h1>
          <p className="text-sm md:text-lg font-inter">
            Filter, Explore, and Expand Your Fashion Collection
          </p>
        </div>
      </div>

      <div className="flex justify-end mx-4">
        <Button
          onClick={handleUploadRedirect}
          className="w-[140px] sm:w-[160px] md:w-[184px] h-[40px] md:h-[48px]"
          style={{
            background: "#1D3A72",
          }}
        >
          Upload New Image
        </Button>
      </div>

      <div className="container flex flex-col md:flex-row  mx-auto px-4">
        <div className="flex flex-col w-full md:w-1/4 md:sticky mr-6 top-20  -mt-11 mb-6 md:mb-0">
          <h2 className="text-lg md:text-xl font-semibold mb-4">
            Filter by Categories
          </h2>

          <div className="min-w-max p-4 rounded-lg bg-white shadow-lg">
            <div className="mb-8">
              <h3 className="text-lg md:text-lg font-medium mb-2 flex justify-between">
                <span className="font-semibold font-inter"> Image Type</span>
                <button
                  className="ml-2 text-sm text-gray-700"
                  onClick={() => setCategoriesDropdownOpen((prev) => !prev)}
                >
                  <img
                    src={categoriesDropdownOpen ? ChevronUp : ChevronDown}
                    alt=""
                  />
                </button>
              </h3>

              {categoriesDropdownOpen && (
                <div className="space-y-4">
                  {Object.keys(ImageTypeViewNames).map((key) => (
                    <div key={key} className="flex items-center">
                      <input
                        type="checkbox"
                        id={key}
                        checked={selectedCategory.includes(key)}
                        onChange={() => handleCategoryChange(key)}
                        className="hidden"
                      />
                      <div
                        className={`h-6 w-6 border-1 rounded-sm flex items-center justify-center cursor-pointer ${
                          selectedCategory.includes(key)
                            ? "bg-yellow-500 border-transparent"
                            : "border-gray-400 bg-white"
                        }`}
                        onClick={() => handleCategoryChange(key)}
                      >
                        {selectedCategory.includes(key) && (
                          <img
                            src={CheckIcon}
                            className="w-4 h-4 text-black"
                            alt="Check icon"
                          />
                        )}
                      </div>
                      <label
                        htmlFor={key}
                        className="ml-3 text-sm font-medium text-gray-900"
                      >
                        {ImageTypeViewNames[key]}
                      </label>
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className="border-t border-gray-300 w-full my-4"></div>
            <div className="flex items-center" style={{ gap: "0px" }}>
              <input
                type="radio"
                id="catalogue"
                name="catalogue"
                value="BACKDROP"
                className="w-10 h-10 opacity-0 absolute"
                checked={selectedCatalogue}
                onClick={(e) => handleCatalogue(e.target.value)}
                readOnly
              />

              <label
                htmlFor="catalogue"
                className="flex items-center justify-center w-[40px] h-[40px] border-2 border-gray-300 rounded-full cursor-pointer"
              >
                {selectedCatalogue ? (
                  <div className="w-4 h-4 bg-yellow-500 rounded-full"></div>
                ) : (
                  <div className="w-4 h-4 bg-gray-500 rounded-full"></div>
                )}
              </label>
              <span className="w-6 h-8 font-medium text-2xl font-inter ml-2">
                Catalogue
              </span>
            </div>
            <div className="border-t border-gray-300 w-full my-4"></div>
            <h3 className="text-lg font-medium mb-2 flex justify-between">
              <span className="mr-1 font-semibold font-inter">
                Generated By
              </span>
              <button
                className="text-sm text-gray-700"
                onClick={() => setGeneratedByDropdownOpen((prev) => !prev)}
              >
                <img
                  src={generatedByDropdownOpen ? ChevronUp : ChevronDown}
                  alt=""
                />
              </button>
            </h3>

            {generatedByDropdownOpen && (
              <div className="space-y-4">
                {Object.keys(AIFeatureCodes).map((key) => (
                  <div key={key} className="flex items-center">
                    <input
                      type="checkbox"
                      id={key}
                      checked={selectedGeneratedBy.includes(key)}
                      onChange={() => handleGeneratedByChange(key)}
                      className="hidden"
                    />
                    <div
                      className={`h-6 w-6 border-1 rounded-sm flex items-center justify-center cursor-pointer ${
                        selectedGeneratedBy.includes(key)
                          ? "bg-yellow-500 border-transparent"
                          : "border-gray-400 bg-white"
                      }`}
                      onClick={() => handleGeneratedByChange(key)}
                    >
                      {selectedGeneratedBy.includes(key) && (
                        <img
                          src={CheckIcon}
                          className="w-4 h-4 text-black"
                          alt="Check icon"
                        />
                      )}
                    </div>
                    <label
                      htmlFor={key}
                      className="ml-3 text-sm font-medium text-gray-900"
                    >
                      {AIFeatureViewNames[key]}
                    </label>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        <div className="w-full mt-2 md:w-3/4 mr-3 flex  justify-center">
          {loading ? (
            <div className="flex justify-center items-center w-full h-[300px]">
              <Loader className="w-10 h-10" />
            </div>
          ) : error ? (
            <div className="flex justify-center items-center w-full h-[300px] bg-gray-200">
              <p className="text-red-600 text-center">{error}</p>
            </div>
          ) : images.length === 0 &&
            !selectedCategory.length &&
            !selectedGeneratedBy.length ? (
            <div className="flex flex-col justify-center items-center w-full h-[300px] bg-gray-200">
              <p className="text-gray-700 text-center font-bold text-xl mb-4">
                Images not present, please upload some images.
              </p>
            </div>
          ) : images.length === 0 ? (
            <div className="flex justify-center items-center w-full h-[300px] bg-gray-200">
              <p className="text-gray-700 text-center text-lg">
                No images available for the selected filters.
              </p>
            </div>
          ) : (
            <div className="columns-1 sm:columns-2 md:columns-3 lg:columns-4 gap-0">
              {images.slice(0, visibleCount).map((image) => (
                <div
                  key={image.id}
                  className="mb-3 break-inside-avoid overflow-hidden border-black"
                >
                  {imageErrors[image.id] ? (
                    <div className="flex items-center justify-center w-full h-[150px] bg-gray-200">
                      <p className="text-red-500 text-center">
                        Image failed to load
                      </p>
                    </div>
                  ) : (
                    <div className="h-[250px] w-[250px] flex justify-center items-center p-2 bg-gray-100">
                      <img
                        src={image.file}
                        alt={image.name}
                        onClick={() => handleImageClick(image)}
                        onLoad={() => handleImageLoad(image.id)}
                        onError={() => handleImageError(image.id)}
                        // className={`w-full h-auto object-cover cursor-pointer transition-all duration-500 ${loadedImages[image.id] ? '' : 'blur-sm'} border border-black rounded-md`}
                        className="h-full w-full object-contain"
                        style={{ display: "block" }}
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      {images.length > visibleCount && (
        <div className="flex justify-center mt-4 mb-8">
          <Button
            onClick={showMoreImages}
            style={{
              background: "#1D3A72",
            }}
          >
            Show More
          </Button>
        </div>
      )}
      {selectedImage && (
        <div
          className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-25"
          onClick={closePreview}
        >
          <div
            className="relative  rounded-lg  p-4 max-w-[90vw] md:max-w-lg  w-full"
            onClick={(e) => e.stopPropagation()}
          >
            <img
              src={selectedImage.file}
              alt={selectedImage.name}
              className="w-full h-[70vh] object-contain"
            />
            <button
              className="absolute top-2 right-2 text-gray-700 hover:bg-red-600 bg-gray-200 border-2 rounded-full p-2"
              onClick={closePreview}
            >
              ×
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Gallary;
