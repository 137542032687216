import React from "react";
import "./UIComponents.css"; // Importing CSS file for styles

export const Loader = () => (
  <div className="nexusware-loader-container">
    <div className="dot" style={{ backgroundColor: "#FEBC0A" }}></div>
    <div className="dot" style={{ backgroundColor: "#E74539" }}></div>
    <div className="dot" style={{ backgroundColor: "#613AB1" }}></div>
    <div className="dot" style={{ backgroundColor: "#4483F5" }}></div>
  </div>
);

export const Button = ({ children, type, onClick }) => (
  <button className="custom-button" type={type} onClick={onClick}>
    {children}
  </button>
);

export const BackgroundSection = () => {
  const gradientBackground = {
    background: "linear-gradient(131deg, #010C23 0%, #28A8BF 0%,  109.52%)",
    height: "100%",
    width: "100%",
  };
};

// background: linear - gradient(, #010C23 0 %,  109.52 %);
