import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../../assets/images/logo.svg";
import { ThemeContext } from "../Utils/ThemeContext";
import { login } from "../../api/authApi";

export const PasswordReset = () => {
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [popup, setPopup] = useState({
    show: false,
    message: "",
    isSuccess: false,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [token, setToken] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const { mode, toggleLight, toggleDark } = useContext(ThemeContext);
  const [resetting, setIsResetting] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const urlToken = params.get("token");

    if (!urlToken) {
      setPopup({
        show: true,
        message:
          "No token found in URL. Please request a new password reset link.",
        isSuccess: false,
      });
      setIsLoading(false);
      return;
    }

    setToken(urlToken);
    setIsLoading(false);
  }, [location]);

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    if (password1 !== password2) {
      setPopup({
        show: true,
        message: "Passwords do not match!",
        isSuccess: false,
      });
      return;
    }

    if (password1.length !== 8) {
      setPopup({
        show: true,
        message: "Passwords should have atleast 8 characters",
        isSuccess: false,
      });
      return;
    }

    try {
      setIsResetting(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/usermgmt/knox/password-reset/confirm/`,
        {
          token,
          password1,
          password2,
        },
        {
          headers: {
            "Content-Type": "application/json",
            accept: "application/json",
          },
        },
      );

      if (response.status === 200) {
        setPopup({
          show: true,
          message: "Password has been reset successfully. Logging you in...",
          isSuccess: true,
        });

        // Auto-login
        try {
          const loginResponse = await login({
            email: response.data.email,
            password: password1,
          });
          if (loginResponse.status === 200) {
            setTimeout(() => navigate("/dashboard"), 2000);
          } else {
            throw new Error("Login failed after password reset");
          }
        } catch (loginError) {
          console.error("Auto-login failed:", loginError);
          setPopup({
            show: true,
            message:
              "Password reset successful. Please log in with your new password.",
            isSuccess: true,
          });
          setTimeout(() => navigate("/dashboard"), 2000);
        }
      }
    } catch (error) {
      console.error("Error resetting password:", error);
      setPopup({
        show: true,
        message:
          error.response?.data?.error ||
          "Error resetting password. Please try again or request a new reset link.",
        isSuccess: false,
      });
    } finally {
      setIsResetting(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleThemeToggle = (event) => {
    if (event.target.checked) {
      toggleDark();
      localStorage.setItem("theme", "dark-mode");
    } else {
      toggleLight();
      localStorage.setItem("theme", "light-mode");
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <React.Fragment>
      <div className={`container-fluid footer_section ${mode}`}>
        <div className="row row-cols-1 row-cols-md-2">
          <div
            className="col-md-6 col-lg-6 p-0"
            style={{ position: "relative", background: "#fff" }}
          >
            <div className="login d-flex h-100">
              <div className="container m-auto">
                <div className="row">
                  <div className="col-lg-10 col-xl-10 flex-wrap align-items-center py-5 mx-auto">
                    <div className="d-flex text-center">
                      <div className="w-100 mb-2">
                        <h3 className="loginpage-heading">Reset Password</h3>
                        <p className="text-center">
                          Enter your new password below.
                        </p>
                      </div>
                    </div>
                    <form onSubmit={handlePasswordReset} autoComplete="off">
                      <div className="form-group mb-3 mt-4">
                        <label htmlFor="password1" className="label pb-2">
                          New Password
                        </label>
                        <div className="password-input-wrapper">
                          <input
                            className="form-control input_field form-controls-field"
                            type={showPassword ? "text" : "password"}
                            id="password1"
                            value={password1}
                            onChange={(e) => setPassword1(e.target.value)}
                            placeholder="Enter new password"
                            required
                          />
                          <i
                            className={`fas ${showPassword ? "fa-eye-slash" : "fa-eye"} password-toggle`}
                            onClick={togglePasswordVisibility}
                          ></i>
                        </div>
                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="password2" className="label pb-2">
                          Confirm New Password
                        </label>
                        <div className="password-input-wrapper">
                          <input
                            className="form-control input_field form-controls-field"
                            type={showPassword ? "text" : "password"}
                            id="password2"
                            value={password2}
                            onChange={(e) => setPassword2(e.target.value)}
                            placeholder="Confirm new password"
                            required
                          />
                        </div>
                      </div>
                      <div className="field">
                        <button
                          className="btn-block w-100 login_button"
                          type="submit"
                        >
                          {resetting
                            ? "Resetting password..."
                            : "Reset Password"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-6 p-0 responsive_div">
            <div className="login-image h-100">
              <img
                src={logo}
                className="img-fluid logo"
                alt="no_img"
                style={{ width: "200px" }}
              />
            </div>
          </div>
        </div>
      </div>
      {popup.show && (
        <div
          style={{ zIndex: "9999" }}
          className={`alert alert-${popup.isSuccess ? "success" : "danger"} alert-dismissible fade show position-fixed top-0 end-0 m-3`}
          role="alert"
        >
          <p>{popup.message}</p>
          <button
            type="button"
            className="btn-close"
            onClick={() => setPopup({ ...popup, show: false })}
            aria-label="Close"
            style={{ border: "none", height: "15px", width: "15px" }}
          ></button>
        </div>
      )}
    </React.Fragment>
  );
};

export default PasswordReset;
